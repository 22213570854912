// require('./bootstrap');
// require('admin-lte');
import Flowbite from 'flowbite';
import $ from 'jquery';
const DataTable = require('datatables.net');


import Alpine from 'alpinejs'
 
window.Alpine = Alpine
 
Alpine.start()


